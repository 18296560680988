import React, { Component } from "react"
import { Map as GoogleMap, GoogleApiWrapper, Marker } from "google-maps-react"

const mapStyles = {
  width: "100%",
  height: "100%",
}

export class Map extends Component {
  render() {
    return (
      <GoogleMap
        google={this.props.google}
        zoom={15}
        style={mapStyles}
        initialCenter={{
          lat: 60.2585054,
          lng: 24.8289167,
        }}
      >
        <Marker onClick={this.onMarkerClick} name={"Hiusstudio Gaia"} />
      </GoogleMap>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBMrq2UmpHmSUSndgt-5xNhvdzI5e4wa6g",
})(Map)
