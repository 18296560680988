import React from "react"
import styled from "styled-components"
import FlexContainer from "../FlexContainer"

const PriceContainer = styled.div`
  width: ${props => (props.width === "70%" ? "70%" : "47%")};
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const ItemContainer = styled.div`
  display: flex;
`
const Item = styled.p`
  width: ${props => (props.width === "50%" ? "50%" : "85%")};
  font-size: 15px;
  margin-bottom: 0;
`
const Price = styled.p`
  width: ${props => (props.width === "50%" ? "50%" : "15%")};
  text-align: right;
  font-size: 15px;
  margin-bottom: 0;
`

const ItemSmall = styled.p`
  text-align: ${props => (props.textAlign === "center" ? "center" : "left")};
  font-size: 13px;
  margin-bottom: 0.5rem;
  @media (max-width: 768px) {
    text-align: center;
  }
`
const PricingHeader = styled.h3`
  margin-bottom: 0;
  text-align: center;
  @media (max-width: 768px) {
    text-align: center;
  }
`

const Pricing = () => (
  <div
    className="pricing"
    id="pricelist"
    style={{
      backgroundColor: `#e7e7e748`,
      margin: `0 auto`,
      maxWidth: 960,
      padding: `2rem 1.45rem 5rem 1.45rem`,
    }}
  >
    <h1
      style={{
        textAlign: `center`,
        marginBottom: `0`,
      }}
    >
      Hinnasto
    </h1>
    <p
      style={{
        textAlign: `center`,
        fontSize: `13px`,
      }}
    >
      (sis. ALV 25,5%)
    </p>
    <hr />
    <FlexContainer justifyContent="center">
      <PriceContainer>
        <ItemContainer>
          <Item>Opiskelijat</Item>
          <Price>-10%</Price>
        </ItemContainer>
        <ItemContainer>
          <Item>Nuoret (11 - 16 vuotta)</Item>
          <Price textAlign="right">-10%</Price>
        </ItemContainer>
        <ItemContainer>
          <Item>Eläkeläiset</Item>
          <Price textAlign="right">-10%</Price>
        </ItemContainer>
        <br></br>
        <ItemContainer>
          <Item
            style={{
              fontStyle: `italic`,
              marginLeft: `auto`,
              marginRight: `auto`,
            }}
          >
            Alennus ei koske isoimpia värjäys- tai permanenttitöitä
          </Item>
        </ItemContainer>
      </PriceContainer>
    </FlexContainer>
    <br></br>
    <PricingHeader>Hiustenleikkaus</PricingHeader>
    <FlexContainer paddingBottom="40px">
      <PriceContainer>
        <br></br>
        <ItemSmall>(sis. pesun ja muotoonkuivauksen)</ItemSmall>
        <ItemContainer>
          <Item>Parturileikkaus 30 min</Item>
          <Price>32€</Price>
        </ItemContainer>
        <ItemContainer>
          <Item>Parturileikkaus 40 min</Item>
          <Price>41€</Price>
        </ItemContainer>
        <br></br>
        <ItemContainer>
          <Item>Kampaamoleikkaus 45 min</Item>
          <Price>46€</Price>
        </ItemContainer>
        <ItemContainer>
          <Item>Kampaamoleikkaus 60 min</Item>
          <Price>58€</Price>
        </ItemContainer>
      </PriceContainer>
      <PriceContainer>
        <br></br>
        <ItemSmall>(ei sis. pesua tai muotoonkuivausta)</ItemSmall>
        <ItemContainer>
          <Item>Otsatukan leikkaus</Item>
          <Price>14€</Price>
        </ItemContainer>
        <ItemContainer>
          <Item>Koneajo</Item>
          <Price>17€</Price>
        </ItemContainer>
        <ItemContainer>
          <Item>Parran/Viiksien muotoilu</Item>
          <Price>17€</Price>
        </ItemContainer>
        <ItemContainer>
          <Item>Lastenleikkaus (0-10 vuotta)</Item>
          <Price>27€</Price>
        </ItemContainer>
        <ItemContainer>
          <Item>Pitkien hiusten tasausleikkaus</Item>
          <Price>30€</Price>
        </ItemContainer>
      </PriceContainer>
    </FlexContainer>

    <PricingHeader>Pesu / Hiushoito</PricingHeader>
    <br></br>
    <FlexContainer paddingBottom="40px">
      <PriceContainer>
        <ItemContainer>
          <Item>Pesu ja kuivaus</Item>
          <Price>20€</Price>
        </ItemContainer>
        <ItemContainer>
          <Item>Pesu ja muotoonkuivaus</Item>
          <Price>36€</Price>
        </ItemContainer>
      </PriceContainer>
      <PriceContainer>
        <ItemContainer>
          <Item>Hiushoito</Item>
          <Price>40€</Price>
        </ItemContainer>
        <p
          style={{
            fontSize: `11px`,
            marginBottom: `0`,
          }}
        >
          (sis. hoidon, hiuspohjan hieronnan, pesun ja muotoonkuivauksen)
        </p>
      </PriceContainer>
    </FlexContainer>

    <PricingHeader>Väri</PricingHeader>
    <ItemSmall textAlign="center">(sis. pesun ja muotoonkuivauksen)</ItemSmall>
    <FlexContainer justifyContent="center" paddingBottom="40px">
      <PriceContainer width="70%">
        <ItemContainer>
          <Item width="50%"></Item>
          <Price
            width="50%"
            textAlign="right"
            style={{
              fontSize: "13px",
            }}
          >
            lyhyet / keskipitkät / pitkät
          </Price>
        </ItemContainer>
        <ItemContainer>
          <Item>Tyviväri (max 3cm)</Item>
          <Price textAlign="right">70€</Price>
        </ItemContainer>
        <ItemContainer>
          <Item>Tyviväri ja leikkaus</Item>
          <Price textAlign="right">100€</Price>
        </ItemContainer>
        <ItemContainer>
          <Item>Tyvivaalennus (max 3cm)</Item>
          <Price textAlign="right">80€</Price>
        </ItemContainer>
        <ItemContainer>
          <Item>Tyvivaalennus ja leikkaus</Item>
          <Price textAlign="right">112€</Price>
        </ItemContainer>
        <br></br>
        <ItemContainer>
          <Item width="50%">Väri</Item>
          <Price width="50%" textAlign="right">
            78€ / 91€ / 102€
          </Price>
        </ItemContainer>
        <ItemContainer>
          <Item width="50%">Väri ja leikkaus</Item>
          <Price width="50%" textAlign="right">
            108€ / 122€ / 126€
          </Price>
        </ItemContainer>
        <ItemContainer>
          <Item width="50%">Kiiltosävy</Item>
          <Price width="50%" textAlign="right">
            57€ / 70€ / 80€
          </Price>
        </ItemContainer>
        <ItemContainer>
          <Item width="50%">Kiiltosävy ja leikkaus</Item>
          <Price width="50%" textAlign="right">
            87€ / 101 € / 107€
          </Price>
        </ItemContainer>
        <br></br>
        <ItemContainer>
          <Item width="50%">Moniväri</Item>
          <Price width="50%" textAlign="right">
            107€ / 132€ / 146€
          </Price>
        </ItemContainer>
        <ItemContainer>
          <Item width="50%">Moniväri ja leikkaus</Item>
          <Price width="50%" textAlign="right">
            126€ / 146€ / 161€
          </Price>
        </ItemContainer>
        <ItemContainer>
          <Item width="50%">Raidat</Item>
          <Price width="50%" textAlign="right">
            94€ / 121€ / 136€
          </Price>
        </ItemContainer>
        <ItemContainer>
          <Item width="50%">Raidat ja leikkaus</Item>
          <Price width="50%" textAlign="right">
            119€ / 137€ / 147€
          </Price>
        </ItemContainer>
        <br></br>
        <ItemContainer>
          <Item>Vaalennuskäsittely/Värinpoisto</Item>
          <Price textAlign="right">61€/h</Price>
        </ItemContainer>
        <br></br>
        <ItemContainer>
          <Item
            style={{
              width: `70%`,
            }}
          >
            Extrapitkät/Paksut hiukset
          </Item>
          <Price
            textAlign="right"
            style={{
              width: `30%`,
            }}
          >
            + 5-15€
          </Price>
        </ItemContainer>
        <br></br>
        <ItemContainer>
          <Item
            style={{
              width: `80%`,
              fontStyle: `italic`,
            }}
          >
            Olethan yhteydessä jos haluat suuren värimuutoksen tai värinpoiston.
          </Item>
        </ItemContainer>
      </PriceContainer>
    </FlexContainer>

    <PricingHeader>Permanentti</PricingHeader>
    <ItemSmall textAlign="center">
      (sis. pesun, hiustenleikkauksen ja muotoonkuivauksen)
    </ItemSmall>
    <FlexContainer justifyContent="center" paddingBottom="40px">
      <PriceContainer width="70%">
        <ItemContainer>
          <Item width="50%"></Item>
          <Price
            width="50%"
            textAlign="right"
            style={{
              fontSize: "13px",
            }}
          >
            lyhyet / keskipitkät / pitkät
          </Price>
        </ItemContainer>
        <ItemContainer>
          <Item>Permanentti (päälliosa)</Item>
          <Price textAlign="right">94€</Price>
        </ItemContainer>
        <ItemContainer>
          <Item width="50%">Permanentti (koko pää)</Item>
          <Price width="50%" textAlign="right">
            108€ / 132€ / 164€
          </Price>
        </ItemContainer>
        <br></br>
        <ItemContainer>
          <Item width="50%">+ Sävytys/Kevytväri</Item>
          <Price width="50%">alk. 16€</Price>
        </ItemContainer>
      </PriceContainer>
    </FlexContainer>

    <PricingHeader>Kulmat ja ripset</PricingHeader>
    <br></br>
    <FlexContainer>
      <PriceContainer>
        <ItemContainer>
          <Item>Kulmien värjäys</Item>
          <Price textAlign="right">16€</Price>
        </ItemContainer>
        <ItemContainer>
          <Item>Ripsien värjäys</Item>
          <Price textAlign="right">19€</Price>
        </ItemContainer>
        <ItemContainer>
          <Item>Ripsien ja kulmien värjäys</Item>
          <Price textAlign="right">27€</Price>
        </ItemContainer>
      </PriceContainer>
      <PriceContainer>
        <ItemContainer>
          <Item>Kulmien muotoilu</Item>
          <Price textAlign="right">16€</Price>
        </ItemContainer>
        <ItemContainer>
          <Item>Kulmien värjäys ja muotoilu</Item>
          <Price textAlign="right">26€</Price>
        </ItemContainer>
        <ItemContainer>
          <Item>Ripsien ja kulmien värjäys ja muotoilu</Item>
          <Price textAlign="right">32€</Price>
        </ItemContainer>
      </PriceContainer>
    </FlexContainer>
  </div>
)

export default Pricing
