import React from "react";
import styled from "styled-components";
import Loc from '../../images/sijainti.svg';
import Phone from '../../images/puhelin.svg';
import Mail from '../../images/kirjekuori.svg';


import Map from "../map"

const FooterContainer = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const ContactContainer = styled.div`
  width: 40%;

  @media (max-width: 768px) {
    width: 100%;
  }
`
const MapContainer = styled.div`
  width: 60%;
  position: relative;
  height: 200px;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const Contact = () => (
  <div
    id="contact"
    style={{
      background: `#263f35ff`,
    }}
  >
    <div
      className="contact"
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.0875rem 1.45rem`,
        paddingBottom: `5rem`,
        paddingTop: `3rem`,
      }}
    >
      <div
        style={{
          display: `inline-block`,
          padding: `0 1.0875rem 1.45rem`,
          width: `100%`,
        }}
      >
        <h1
          style={{
            textAlign: `center`,
            marginTop: `1.45rem`,
            marginBottom: `3rem`,
            color: `white`,
          }}
        >
          Yhteystiedot
        </h1>

        <FooterContainer>
          <ContactContainer style={{
            paddingBottom: "30px"
            }}>
            <div style={{
              display: "flex",
            }}
              >
              <div style={{
                margin: `auto 30px`,
                width: `10px`
              }}>
                <Loc
                  style={{
                    width: `200%`,
                    height: `2000%`,
                  }}
                />
              </div>
              <div>
              <p> Hiusstudio Gaia</p>
          
              <p> Nuijatie 12</p>
              <p> 01650 Vantaa</p>
              </div>
            </div>
            <br/>
            <div style={{
              display: "flex",
            }}
              >
              <div style={{
                margin: `auto 30px`,
                width: `10px`
              }}>
                <Phone
                  style={{
                    width: `200%`,
                    height: `2000%`,
                    paddingBottom: `3px`
                  }}
                />
              </div>
              <div>
                <p>045 279 0199 </p>
              </div>
            </div>

            <br/>

            <div style={{
              display: "flex",
            }}
              >
              <div style={{
                margin: `auto 30px`,
                width: `10px`
              }}>
                <Mail
                  style={{
                    width: `200%`,
                    height: `2000%`,
                    paddingBottom: `2px`
                  }}
                />
              </div>
              <div>
                <p>hiusstudio.gaia@gmail.com</p>
              </div>
            </div>

          </ContactContainer>

          <MapContainer>
            <Map />
          </MapContainer>
        </FooterContainer>
      </div>
    </div>
  </div>
)

export default Contact
