import styled from "styled-components"

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 2rem 1.45rem;
  background-color: ${props =>
    props.backgroundColor === "grey" ? "grey" : "white"};
`

export default Container
