import React from "react"

const Info = () => (
  <div
    style={{
      backgroundColor: `#e7e7e748`,
    }}
  >
    <div
      className="info"
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `2rem 1.45rem 5rem 1.45rem`,
      }}
    >
      <br />
      <p>
        Hiusstudio Gaia on elokuussa 2020 avattu parturi-kampaamo joka
        sijaitsee Länsi-Vantaan Rajatorpassa, Myyrmäen Vapaalan kaupunginosassa.
        Gaiassa on rauhallinen, lämmin ja rento ilmapiiri, jossa saat
        ammattitaitoisesti toteutetun, juuri sinulle luodun palvelun.
      </p>
      <p>
        Voit tehdä ajanvarauksen netissä 24/7 tai puhelimitse (ti-pe klo 10-19,
        lauantaisin klo 10-15) tai paikan päällä. Auki sopimuksen mukaan
        (ti-la). Tervetuloa!
      </p>
      <br />
      <h4>Vähän minusta</h4>

      <p>
        Olen valmistunut 2012 hiusmuotoilijaksi (PROF Helsinki) ja 2016
        parturi-kampaajaksi (Vantaan ammattiopisto Varia), jonka jälkeen olen
        toiminut hiusalan yrittäjänä.
      </p>
      <p>
        Työssäni tärkeintä on toteuttaa sinun toiveidesi ja tyylisi mukainen
        lopputulos sekä luoda viihtyisä ja mukava ilmapiiri. Jokainen hius on
        minulle taideteos.
      </p>
      <p>Työskentelen yksin.</p>
      <p>- Jenna</p>
    </div>
  </div>
)

export default Info
