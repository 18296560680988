import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Info from "../components//sections/info"
import Pricing from "../components//sections/pricing"
import Gallery from "../components//sections/gallery"
import Contact from "../components//sections/contact"
import Reservation from "../components//sections/reservation"
import Open from "../components//sections/Open"

import "bootstrap/dist/css/bootstrap.min.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Gaia" />
    <Info />
    <Reservation />
    <Pricing />
    <Gallery />
    <Contact />
    <Open />
  </Layout>
)

export default IndexPage
