import React from "react"
import Container from "../Container"
import IframeResizer from "iframe-resizer-react"

const Reservation = () => {
  return (
    <Container className="reservation" id="reservation">
      <h1
        style={{
          textAlign: `center`,
        }}
      >
        Ajanvaraus
      </h1>

      <hr />
      <IframeResizer
        height="680px"
        width="100%"
        frameBorder="0"
        src="https://varaa.timma.fi/reservation/hiusstudiogaia"
        id="reservationIframe12879"
      ></IframeResizer>
    </Container>
  )
}

export default Reservation
