import styled from "styled-components"

const FlexContainer = styled.div`
  display: flex;
  justify-content: ${props =>
    props.justifyContent === "center" ? "center" : "space-between"};
  padding-bottom: ${props => (props.paddingBottom === "40px" ? "40px" : "0px")};
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export default FlexContainer
