import Container from "../Container"
import Gallery from "react-photo-gallery"
import React, { useState, useCallback } from "react"
import Carousel, { Modal, ModalGateway } from "react-images"

const photos = [
  {
    src: "/16.jpg",
    width: 617,
    height: 800,
  },
  {
    src: "/17.jpg",
    width: 681,
    height: 790,
  },
  {
    src: "/18.jpg",
    width: 699,
    height: 800,
  },
  {
    src: "/01.jpg",
    width: 700,
    height: 833,
  },
  {
    src: "/02.jpg",
    width: 489,
    height: 630,
  },
  {
    src: "/03.jpg",
    width: 515,
    height: 630,
  },
  {
    src: "/04.jpg",
    width: 539,
    height: 677,
  },
  {
    src: "/05.jpg",
    width: 537,
    height: 630,
  },
  {
    src: "/06.jpg",
    width: 597,
    height: 630,
  },
  {
    src: "/07.jpg",
    width: 485,
    height: 628,
  },
  {
    src: "/08.jpg",
    width: 584,
    height: 630,
  },
  {
    src: "/09.jpg",
    width: 548,
    height: 619,
  },
  {
    src: "/10.jpg",
    width: 570,
    height: 700,
  },
  {
    src: "/11.jpg",
    width: 504,
    height: 630,
  },
  {
    src: "/12.jpg",
    width: 597,
    height: 693,
  },
  {
    src: "/13.jpg",
    width: 497,
    height: 666,
  },
  {
    src: "/14.jpg",
    width: 730,
    height: 769,
  },
  {
    src: "/15.jpg",
    width: 949,
    height: 591,
  },
]

const shopphotos = [
  {
    src: "/gaialiike1.JPG",
    width: 988,
    height: 738,
  },
  {
    src: "/gaialiike2.JPG",
    width: 1000,
    height: 654,
  },
  {
    src: "/gaialiike3.JPG",
    width: 1000,
    height: 722,
  },
  {
    src: "/gaialiike4.JPG",
    width: 700,
    height: 766,
  },
  {
    src: "/gaialiike5.JPG",
    width: 700,
    height: 765,
  },
  {
    src: "/minä.jpg",
    width: 650,
    height: 976,
  },
]

function GallerySection() {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const [currentImageShop, setCurrentImageShop] = useState(0)
  const [viewerIsOpenShop, setViewerIsOpenShop] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const openLightboxShop = useCallback((event, { photo, index }) => {
    setCurrentImageShop(index)
    setViewerIsOpenShop(true)
  }, [])

  const closeLightboxShop = () => {
    setCurrentImageShop(0)
    setViewerIsOpenShop(false)
  }
  return (
    <Container className="gallery" id="gallery">
      <h1
        style={{
          textAlign: `center`,
        }}
      >
        {" "}
        Galleria{" "}
      </h1>
      <hr />
      <div>
        <Gallery photos={shopphotos} onClick={openLightboxShop} />
        <ModalGateway>
          {viewerIsOpenShop ? (
            <Modal onClose={closeLightboxShop}>
              <Carousel
                currentIndex={currentImageShop}
                views={shopphotos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
      <hr></hr>
      <div>
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
      <br />
      <br />
      <br />
    </Container>
  )
}

export default GallerySection
