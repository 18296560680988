import React from "react";
import styled from "styled-components";

const FlexContainer = styled.div`
    margin: 10px auto;
    display: flex;
    justify-content: space-between;
    max-width: 450px;
`;
const OpenText = styled.p`
    color: #746767ff !important;
    font-size: 23px;
    font-weigth: 600;
`;


const Open = () => (
  <div
    id="contact"
    style={{
      background: `#dededeff`,
    }}
  >
    <div
      className="contact"
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.0875rem 1.45rem`,
        paddingBottom: `5rem`,
        paddingTop: `3rem`,
      }}
    >
      <div
        style={{
          display: `inline-block`,
          padding: `0 1.0875rem 0.5rem`,
          width: `100%`,
        }}
      >
        <h3
          style={{
            textAlign: `center`,
            marginTop: `1.45rem`,
            marginBottom: `2rem`,
            color: `#746767ff`,
            fontWeight: `600`
          }}
        >
          Avoinna sopimuksen mukaan:
        </h3>
      </div>
      <div 
        style={{
            textAlign: `center`,
        }}>
        <FlexContainer>
            <OpenText
            >
            ti-pe 
            </OpenText>
            <OpenText>
            10-19 välisenä aikana
            </OpenText>
        </FlexContainer>
        <FlexContainer>
            <OpenText>
            la
            </OpenText>
            <OpenText>
            10-15 välisenä aikana
            </OpenText>
        </FlexContainer>
        <FlexContainer>
            <OpenText>
            su 
            </OpenText>
            <OpenText>
            suljettu
            </OpenText>
        </FlexContainer>
        <FlexContainer>
            <OpenText>
            ma
            </OpenText>
            <OpenText>
            suljettu
            </OpenText>
        </FlexContainer>
      </div>
    </div>
  </div>
)

export default Open
